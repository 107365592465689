@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

.mainContainerLandingPage {
  width: 100%;
  height: 100dvh;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  scroll-behavior: smooth;
}

.contAllDataLandingPage {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  display: block;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.contAllDataLandingPage::-webkit-scrollbar-thumb {
  background: #c6b4b4;
}

.contAllDataLandingPage::-webkit-scrollbar-track {
  background: rgb(255, 255, 255);
  border: 0.5px solid #919191;
  border-radius: 10px;
}

.contAllDataLandingPage::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

@media (max-width: 942px) {
  .contAllDataLandingPage {
    padding-left: 15px;
    padding-right: 15px;
  }
}
