.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #072654;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.cont-spiner-cotizador {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-spiner-cotizador > div {
  width: 90px;
  height: 90px;
}

.cont-header-desarrollo-mapa {
  width: 100vw;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.cont-header-desarrollo-mapa > h1 {
  z-index: 6;
  font-size: 25px;
  font-weight: 400;
  color: black;
  text-align: center;
}

.cont-vertical-status-icons {
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-left: 0;
  margin-right: auto;
  margin-top: 130px;
  margin-bottom: auto;
  width: 100px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: red; */
  overflow: hidden;
}

.cont-vertical-status-icons > div {
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: blue; */
}

.cont-prin-cotizador-map {
  margin-top: 0px;
  /* background-color: rgba(0, 0, 0, 0.9); */
  overflow-y: scroll;
  overflow: scroll;
  width: 100vw;
  height: 150vh;
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

/*  */

.plano-petzbalam {
  width: 100vw;
  height: 100%;
  margin-top: 0px;
  margin-left: -150px;
}

.plano-petzbalam path {
  /* fill:#00ba00; */
  /* stroke:black; */
  /* stroke-width: 2px; */
}

.plano-petzbalam polygon:hover {
  fill: #6f954abe;
  cursor: pointer;
}

.plano-petzbalam path:hover {
  fill: #6f954abe;
  cursor: pointer;
}

.plano-petzbalam rect:hover {
  fill: #6f954abe;
  cursor: pointer;
}

/*  */

.titulo-modal-cotizador {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body {
  /* padding: 0px !important; */
  /* margin: 0px !important; */
}

.form-modal-cotizador-main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px !important;
  margin: 0px !important;
  /* background-color: red; */
}

.cont-1-datos-cotizador-info {
  width: 33%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: rgb(0, 0, 0);
  padding: 35px;
}

.cont-1-datos-cotizador-info > label {
  font-weight: 600;
  color: rgb(196, 192, 0);
  font-size: 25px;
}

.cont-1-datos-cotizador-info > h4 {
  font-size: 19px;
  font-weight: 400;
  color: white;
  margin-bottom: 23px;
}

.cont-1-datos-cotizador-operaciones {
  padding: 35px;
  width: 67%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 0.5px solid #b9b9b9;
  /* background: #cf232300; */
  overflow: scroll;
  /* border-radius: 5px; */
}

.cont-1-datos-cotizador-operaciones select {
  width: 80%;
  text-align-last: center;
  margin-bottom: 20px;
}

.contenedor-opciones-pago {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.contenedor-opciones-pago > label {
  width: 150px;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.cont-principal-input {
  width: 180px;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cont-principal-input-1 {
  width: 120px;
  height: 42px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cont-principal-input > .cont-principal-input-icons {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #d7d7d7;
}

.cont-principal-input > .cont-principal-input-input {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.contenedor-opciones-pago-equals {
  margin-left: 20px;
  margin-right: 20px;
}

.cont-principal-input-icons {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #d7d7d7;
}

.cont-principal-input-icons > i {
  font-size: 14px;
}

.cont-principal-input-input {
  width: 65%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.cont-principal-input-input > input[type="text"] {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: #00000000;
}

/*  */

.cont-agregar-datos-nuevo-cotizado {
  margin-top: 10px;
  width: 100%;
  height: 270px;
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.btn-cambiar-colaborador-cotizacion {
  width: 170px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  background-color: rgb(146, 34, 0);
  cursor: pointer;
  margin-bottom: 10px;
}

.btn-cambiar-colaborador-cotizacion > label {
  color: white;
  text-align: center;
  cursor: pointer;
}

/*  */
@media (max-width: 1200px) {
  /* .cont-principal-input {
    width: 90px;
  } */
}

@media (max-width: 900px) {
  /* .cont-prin-cotizador-map {
    height: 100vh;
  } */
}

@media (max-width: 995px) {
  .form-modal-cotizador-main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    /* background-color: red; */
  }

  .cont-1-datos-cotizador-info {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
  }

  .cont-1-datos-cotizador-operaciones {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: yellow; */
  }

  .cont-1-datos-cotizador-operaciones {
    overflow: scroll;
  }
}

@media (max-width: 700px) {
  .cont-prin-cotizador-map {
    width: 100vw;
    height: 100%;
  }

  .cont-vertical-status-icons {
    display: none;
  }

  .plano-petzbalam {
    margin-top: 50px;
    margin-left: -50px;
  }
}

@media (max-width: 590px) {
  .contenedor-opciones-pago {
    flex-flow: row wrap;
  }

  .contenedor-opciones-pago > label {
    width: 100%;
  }

  .contenedor-opciones-pago-equals {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 513px) {
  .contenedor-opciones-pago > label {
    width: 90%;
  }

  .contenedor-opciones-pago-equals {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8px;
  }

  .contenedor-opciones-pago-equals {
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media (max-width: 550px) {
  .cont-prin-cotizador-map {
    width: auto;
    height: auto;
  }
  .plano-petzbalam {
    width: 100%;
    height: 500px;
    margin-top: 0px;
    margin-left: 0px;
  }
}

@media (max-width: 499px) {
  .cont-prim-btns-cotizador {
    width: 100%;
    height: 110px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* background-color: red; */
  }

  .btn-apartar-form {
    margin-left: 0px !important;
  }
}
