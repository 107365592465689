.contenedor-principal-login > form h3 {
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.contenedor-principal-login > form p {
  width: 90vh;
  font-size: 18px;
  margin-bottom: 25px;
  color: white;
  text-align: center;
}

@media (max-width: 700px) {
  .contenedor-principal-login > form p {
    width: 40vh;
  }
}
