.react-bootstrap-table {
}

.react-bootstrap-table table {
  width: 100%;
  text-align: center;
  /* background-color: red; */
}

.react-bootstrap-table table thead tr > th {
  text-align: center;
  overflow-x: scroll;
}

.react-bootstrap-table table thead tr > th::-webkit-scrollbar:horizontal {
  height: 3px;
}

.react-bootstrap-table table tbody > tr td {
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow: hidden !important;
}

.react-bootstrap-table table tbody > tr td::-webkit-scrollbar:horizontal {
  height: 4px;
}

.table-actions {
  /* background: red; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.table-actions > .btn {
  outline: none !important;
  border: none !important;
  text-decoration: none !important;
  background-color: none !important;
}

.table-actions > h5 {
  margin-left: 10px;
  margin-right: 10px;
  outline: none !important;
  border: none !important;
  text-decoration: none !important;
  background-color: none !important;
  /* background: blue; */
}

.table-actions > h5 > button {
  outline: none !important;
  border: none !important;
  text-decoration: none !important;
  background-color: none !important;
}

.modal-dialog .close {
  border: none;
  background: none;
}

.modal-dialog .close > span {
  font-size: 29px;
  color: white;
}

.modal-header {
  height: 55px;
}

.react-bootstrap-table-pagination {
  width: 100%;
  margin-bottom: 20px;
  overflow-y: scroll;
}

.cont-find-container strong {
  font-size: 16px;
}

.cont-find-container input {
  margin-left: 6px;
  border-radius: 3px;
  height: 38px;
  outline: none;
  text-align: center;
  padding: 3px;
  background: white;
  font-size: 15px;
  border: 1.5px solid rgba(32, 31, 31, 0.414);
  width: 270px;
}

.cont-find-container input {
  display: flex;
  flex-flow: column nowrap;
}

@media (max-width: 750px) {
  .react-bootstrap-table table {
    width: 200%;
  }
}
