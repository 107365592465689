.cont-form-soporte {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.cont-form-soporte > form {
  width: 80%;
  height: 420px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #072654;
  border-radius: 10px;
  overflow: hidden;
  /* background-color: red; */
}

.cont-form-soporte > form > input {
  width: 350px;
  height: 43px;
  outline: none;
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

.cont-form-soporte > form > input::placeholder {
  color: #b8b2b2;
}

.cont-form-soporte > form > textarea {
  width: 350px;
  height: 110px;
  outline: none;
  font-size: 15px;
  padding-top: 12px;
  padding-left: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
}

.cont-form-soporte > form > textarea::placeholder {
  color: #b8b2b2;
}

.cont-form-soporte > form > input[type="submit"] {
  width: 350px;
  height: 43px;
  outline: none;
  font-size: 15px;
  padding-left: 10px;
  margin-bottom: 10px;
  background: black;
  border: none;
  color: white;
  border-radius: 4px;
}

.cont-form-soporte > form > input[type="submit"]:hover {
  background: rgb(40, 40, 40);
}

@media (max-width: 600px) {
  .cont-form-soporte > form > input {
    width: 85%;
  }

  .cont-form-soporte > form > textarea {
    width: 85%;
  }
  .cont-form-soporte > form > input[type="submit"] {
    width: 85%;
  }
}

@media (max-width: 500px) {
  .cont-form-soporte > form {
    width: 95%;
  }
}
