.mainContPropiedades2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainContPropiedades2 > h3 {
  margin-top: 40px;
  width: 100%;
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  color: #c9a857;
  margin-bottom: -5px;
}

.borderDecoradorPropiedades {
  height: 2px;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 90px !important;
  background-color: #c9a857;
}

.mainContPropiedades2 > p {
  width: 100%;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.cont-div-main-propiedades2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 700px) {
  .mainContPropiedades2 > h3 {
    margin-top: 0px;
  }
}

@media (max-width: 760px) {
  .mainContPropiedades2 > h3 {
    font-size: 23px;
    margin-bottom: 0px;
  }
}
