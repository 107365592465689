.mainContMapaLocacion {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainContMapaLocacion > iframe {
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
}

@media (max-width: 750px) {
  .mainContMapaLocacion {
    margin-bottom: 30px;
  }

  .mainContMapaLocacion > iframe {
    margin-top: 40px;
  }
}
