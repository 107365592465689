@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");

.mainContHeader {
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contMenuHeaderMovil {
  display: none;
}

.contLogoHeader {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
}

.imgLogoHeader {
  height: 60%;
  object-fit: contain;
}

.contMenuHeader {
  width: 75%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
}

.navLinkHeader {
  margin-left: 30px;
}

.navLinkHeader > label {
  color: rgb(58, 58, 58);
  font-size: 15px;
  transition-duration: 0.4s;
  text-transform: uppercase;
  /* font-weight: 700; */
  font-family: "Josefin Sans", sans-serif;
}

.navLinkHeader > label:hover {
  color: #c9a857;
  font-size: 15.1px;
  transition-duration: 0.4s;
  cursor: pointer;
}

.navLinkHeaderActive > label {
  color: #c9a857;
  transition-duration: 0.4s;
}

@media (max-width: 760px) {
  .mainContHeader {
    padding-left: 0px;
    padding-right: 0px;
    height: 60px;
  }

  .contMenuHeader {
    display: none;
  }

  .contMenuHeaderMovil {
    width: 75%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
  }

  .imgMenu {
    width: 30px;
    height: 30px;
    object-fit: contain;
    user-select: none;
    cursor: pointer;
    user-select: none;
  }
}
