.cont-all-principal-dashboard {
  width: auto;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
}

.cont-principal-dashboard {
  background-color: #ffffff;
  margin-top: 9px;
  /* background-color: #004992; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cont-principal-dashboard-item {
  /* background-color: rgb(105, 8, 8); */
  width: 100%;
  height: 170px;
  min-height: 170px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.cont-principal-header-item-1 {
  /* background-color: rgb(216, 0, 0); */
  width: 65%;
  height: 200px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-principal-header-item-1-perfil {
  /* background-color: rgb(195, 255, 0); */
  width: 15%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.cont-principal-header-item-1-perfil > div {
  /* background-color: rgb(0, 0, 0); */
  width: 65px;
  height: 65px;
  display: flex;
  border-radius: 50%;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-principal-header-item-1-perfil > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  user-select: none;
}

.cont-principal-header-item-1-desc-1 {
  /* background-color: rgb(0, 184, 154); */
  width: 85%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  padding-left: 25px;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  user-select: none;
}

.cont-principal-header-item-1-desc-1 > h1 {
  font-size: 35px;
}

.cont-principal-header-item-1-desc-2 {
  margin-top: -3px;
  /* background-color: rgb(255, 255, 255); */
  width: 100%;
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}

.cont-principal-header-item-1-desc-2 i,
p {
  padding: 0px;
  height: 100%;
  /* background-color: red; */
}

.cont-principal-header-item-1-desc-2 i {
  margin-right: 10px;
  font-size: 18px;
}

.cont-principal-header-item-1-desc-2 p {
  font-size: 15px;
  font-weight: 400;
  color: grey;
}

.cont-principal-header-item-2 {
  /* background-color: rgb(110, 110, 110); */
  width: 35%;
  height: 200px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-principal-header-item-2 > a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  width: 150px;
  height: 40px;
  overflow: hidden;
  margin-left: 7px;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: blue;
  border-radius: 20px;
}

.cont-principal-header-item-2 i {
  margin-right: 5px;
  font-size: 20px;
}

.cont-principal-header-item-2 a {
  font-size: 14px;
}

.cont-principal-header-item-2 i:hover {
  cursor: pointer;
  color: rgb(217, 217, 217);
}

.cont-principal-header-item-2 label:hover {
  cursor: pointer;
  color: rgb(217, 217, 217);
}

.cont-principal-header-item-2 a:hover {
  cursor: pointer;
  background-color: rgb(22, 22, 223);
  color: rgb(217, 217, 217);
}

.div-divider-title-dash-vista {
  width: 100%;
  height: 60px;
  min-height: 60px;
  padding-top: 20px;
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 37px;
  background-color: #f1f5f9;
  overflow: hidden;
}

.cont-user-preview {
  width: 100%;
  height: 270px;
  min-height: 270px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap;
  padding-left: 37px;
  padding-right: 37px;
  padding-top: 15px;
  background-color: #f1f5f9;
  overflow: hidden;
  z-index: 10;
}

.cont-user-item-all {
  width: 23%;
  height: 230px;
  min-height: 230px;
  /* margin-right: 10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  padding: 10px;
  border-radius: 25px;
  background: rgb(255, 255, 255);
  overflow: hidden;
}

.cont-user-item-all a {
  left: 42%;
  bottom: 5px;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  align-items: center;
  /* background-color: red; */
  color: rgba(157, 157, 157, 0.715);
  font-size: 22px;
  position: relative;
  transition: 0.4s;
}

.cont-user-item-all a:hover {
  color: grey;
  cursor: pointer;
  background-color: rgba(0, 13, 255, 0.061);
  transition: 0.4s;
}

.cont-user-item-all label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  /* color: rgb(62, 50, 197); */
  bottom: 20px;
  font-size: 62px;
  position: relative;
}

.cont-user-item-all strong {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  /* color: rgb(62, 50, 197); */
  bottom: 33px;
  font-size: 21px;
  position: relative;
}

.cont-por-vencer {
  width: 100%;
  height: 30px;
  margin-top: 0px;
  /* background: red; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.cont-por-vencer p,
p {
  font-size: 17px;
  color: grey;
}

@media (max-width: 900px) {
  .cont-user-preview {
    height: 520px;
    min-height: 520px;
    margin-bottom: 10px;
  }

  .cont-user-item-all {
    width: 48%;
    margin-bottom: 4%;
  }
}

@media (max-width: 700px) {
  .cont-principal-dashboard-item {
    flex-flow: column nowrap;
    justify-content: center;
    height: 190px;
    min-height: 190px;
  }

  .cont-principal-header-item-1 {
    width: 100%;
    height: 250px;
  }

  .cont-principal-header-item-2 {
    width: 100%;
  }

  .cont-principal-header-item-1-perfil {
    width: 20%;
  }

  .cont-principal-header-item-1-desc-1 > h1 {
    font-size: 25px;
  }
}

@media (max-width: 600px) {
  .div-divider-title-dash-vista {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cont-user-preview {
    padding-left: 20px;
    padding-right: 20px;
    height: 1000px;
    min-height: 1000px;
    margin-bottom: 0px;
    justify-content: space-around;
    /* padding-bottom: 120px; */
  }

  .cont-user-item-all {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 455px) {
  .cont-principal-dashboard {
    margin-top: 20px;
  }

  .cont-principal-header-item-1-perfil {
    width: 28%;
  }

  .cont-principal-header-item-1-desc-2 {
    height: 55px;
  }

  .cont-principal-header-item-1-desc-2 i {
    font-size: 15px;
  }

  .cont-principal-header-item-1-desc-2 p {
    font-size: 13px;
  }
}
