.content-prin-background-notificacion {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.436);
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 50;
}

.content-prin-notificacion-1 {
  position: relative;
  /* left: 0%; */
  width: 35%;
  height: 100vh;
  background-color: #f1f5f9;
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  box-shadow: 0px 2px 8px 10px rgba(0, 0, 0, 0.2),
    10px 6px 5px 5px rgba(0, 0, 0, 0.044);
}

.content-prin-notificacion-0 {
  width: 35%;
  height: 100vh;
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.content-prin-notificacion-header {
  width: 100%;
  min-height: 70px;
  height: 70px;
  background-color: #f1f5f9;
  transition: 0.5s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.cont-img-notificacion-logo {
  width: 70%;
  height: 100%;
  /* background-color: #95ff00; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-img-notificacion-logo > label {
  font-weight: bold;
  font-size: 27px;
}

.cont-img-notificacion-menu {
  width: 30%;
  height: 100%;
  /* background-color: #f700f2; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-img-notificacion-menu div {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
}

.cont-img-notificacion-menu div:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.145);
  transition: 0.3s;
}

.cont-img-notificacion-menu div > img {
  width: 60%;
  height: 60%;
  object-fit: contain;
  user-select: none;
}

.content-segun-notificacion-all {
  width: 100%;
  height: 74%;
  /* background-color: #83aaec; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cont-footer-notificacion-menu {
  position: absolute;
  bottom: 0px;
  width: 100%;
  min-height: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: red; */
}

.cont-footer-notificacion-menu > label {
  font-weight: 600;
  font-size: 18px;
  color: grey;
  user-select: none;
}

.cont-footer-notificacion-menu > label:hover {
  color: black;
  cursor: pointer;
}

.content-noti {
  width: 90%;
  height: auto;
  min-height: 100px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  background-color: white;
  margin-bottom: 18px;
}

.content-noti > strong {
  max-width: 90%;
  /* background-color: red; */
  font-weight: bold;
  color: black;
  font-size: 16px;
}

.content-noti > p {
  font-weight: 400;
  color: black;
  text-align: justify;
  font-size: 14px;
  user-select: none;
}

.cont-close-title-noti {
  width: 100%;
  height: 30px;
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  /* background-color: yellow; */
}

.cont-img-notificacion-menu-item {
  width: 10%;
  height: 100%;
  /* background-color: #f700f2; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-img-notificacion-menu-item div {
  width: 25px;
  height: 25px;
  min-height: 25px;
  min-width: 25px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
}

.cont-img-notificacion-menu-item div:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.145);
  transition: 0.3s;
}

.cont-img-notificacion-menu-item div > img {
  width: 60%;
  height: 60%;
  object-fit: contain;
  user-select: none;
}

.cont-sin-noti {
  width: 80%;
  height: 80%;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-sin-noti > label {
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  color: grey;
}

/*  */
.content-prin-notificacion-2 {
  width: 65%;
  height: 100vh;
  /* background-color: #687200; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 1100px) {
  .content-prin-notificacion-1 {
    width: 35%;
  }

  .content-prin-notificacion-2 {
    width: 65%;
  }
}

@media (max-width: 920px) {
  .content-prin-notificacion-1 {
    width: 40%;
  }

  .content-prin-notificacion-2 {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .content-prin-notificacion-1 {
    width: 50%;
  }

  .content-prin-notificacion-2 {
    width: 50%;
  }
}

@media (max-width: 650px) {
  .content-prin-notificacion-1 {
    width: 65%;
  }

  .content-prin-notificacion-2 {
    width: 35%;
  }
}

@media (max-width: 480px) {
  .cont-img-notificacion-menu-item div {
    width: 20px;
    height: 20px;
    min-height: 20px;
    min-width: 20px;
  }

  .content-prin-notificacion-1 {
    width: 80%;
  }

  .content-prin-notificacion-2 {
    width: 20%;
  }
}
