.cont-ico-whatsapp {
  width: 55px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00bb2d;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  right: 30px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 10;
}

.cont-ico-whatsapp > img {
  width: 50%;
  height: 50%;
  object-fit: contain;
  cursor: pointer;
}
