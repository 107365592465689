@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");

h1 {
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Poppins", sans-serif;
}

.content-spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #072654;
}

.cont-img-loading {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  /* background: red; */
}

.cont-img-loading > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Estilos para motores Webkit y blink (Chrome, Safari, Opera... )*/

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 6px;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #797979c9;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
}

.loader {
  width: 16px;
  height: 16px;
  position: relative;
  left: -32px;
  border-radius: 50%;
  color: #fff;
  background: currentColor;
  box-shadow: 32px 0, -32px 0, 64px 0;
}

.loader::after {
  content: "";
  position: absolute;
  left: -32px;
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 10px;
  background: #ffffff;
  animation: move 3s linear infinite alternate;
}

@keyframes move {
  0%,
  5% {
    left: -32px;
    width: 16px;
  }
  15%,
  20% {
    left: -32px;
    width: 48px;
  }
  30%,
  35% {
    left: 0px;
    width: 16px;
  }
  45%,
  50% {
    left: 0px;
    width: 48px;
  }
  60%,
  65% {
    left: 32px;
    width: 16px;
  }

  75%,
  80% {
    left: 32px;
    width: 48px;
  }
  95%,
  100% {
    left: 64px;
    width: 16px;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
