.cont-main-desc-propiedad {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
  overflow-y: scroll;
  overflow-x: hidden;
}

.cont-main-desc-propiedad-div-2 {
  overflow-y: scroll;
  overflow-x: hidden;
}

.cont-back-home-descpropiedad {
  margin-top: 110px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 25px;
}

.cont-back-home-descpropiedad > div {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 90px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgb(11, 0, 86);
  border-radius: 30px;
  cursor: pointer;
}

.cont-back-home-descpropiedad > div > img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  cursor: pointer;
}

.cont-back-home-descpropiedad > div > a {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
  margin-left: 10px;
  cursor: pointer;
}

.cont-div1-div2-propiedad {
  width: 100%;
  height: 450px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(13, 91, 139); */
  overflow-y: hidden;
  overflow-x: hidden;
  padding-left: 30px;
  padding-right: 30px;
}

.cont-div1-main-desc-propiedad {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(236, 196, 0); */
  overflow: hidden;
}

.cont-div1-main-desc-propiedad > .carousel {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.cont-div1-main-desc-propiedad > img {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
}

.carousel-item {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.carousel-inner {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cont-div2-main-desc-propiedad {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 25px;
  /* background-color: rgb(183, 37, 14); */
  /* overflow-y: scroll; */
}

.cont-div2-main-desc-propiedad > .fecha-crecion {
  color: #ab9a9a;
  font-weight: 500;
  font-size: 13.5px;
}

.cont-header-desc-propiedades {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
}

.cont-img-header-desc-propiedades {
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: rgb(37, 109, 151); */
  overflow: hidden;
}

.cont-img-header-desc-propiedades > img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.cont-img-header-desc-propiedades > label {
  font-size: 15px;
  font-weight: 400;
  color: black;
  margin-left: 5px;
}

.cont-precio-header-desc-propiedades {
  width: 50%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(95, 168, 7); */
  overflow: hidden;
}

.tipo-venta-desc-propiedades {
  width: 90px;
  height: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /* background-color: green; */
}

.tipo-venta-desc-propiedades > label {
  font-size: 22px;
  font-weight: 300;
  color: black;
  text-transform: uppercase;
}

.cont-precio-header-desc-propiedades > label {
  font-size: 22px;
  font-weight: bold;
  color: black;
}

.cont-div2-main-desc-propiedad > h1 {
  margin-top: 15px;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  color: black;
}

.subtitle-detalles-propiedades {
  margin-top: 15px;
  font-size: 19px;
  font-weight: 400;
  text-align: center;
  color: black;
  margin-bottom: 20px;
}

.cont-contacto-logo-detalles {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
}

.cont-contacto-logo-detalles-div1 {
  width: 100px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* background-color: rgb(20, 40, 106); */
  overflow: hidden;
}

.cont-contacto-logo-detalles-div1 > img {
  width: 70px;
  height: 70px;
  object-fit: contain;
  background-color: #022a51;
  border-radius: 100%;
  overflow: hidden;
}

.cont-contacto-logo-detalles-div2 {
  padding-left: 0px;
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: rgb(22, 150, 41); */
  overflow: hidden;
  text-decoration: none;
}

.cont-contacto-logo-detalles-div2 > label {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  color: black;
  text-decoration: none;
}

.cont-contacto-solicitar-informacion {
  width: 200px;
  height: 35px;
  border-radius: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #c9a857;
  margin-top: 5px;
  cursor: pointer;
  text-decoration: none;
}

.cont-contacto-solicitar-informacion > label {
  text-align: center;
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.cont-caracteristicas-main-detalles {
  width: 100%;
  min-height: 110;
  max-height: 170px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #022a51;
  border-radius: 7px;
}

.cont-caracteristicas-main-detalles > div {
  padding: 15px;
  width: 33.3%;
  min-height: 55px;
  max-height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* background-color: #7a848e; */
  border: 0.5px solid rgba(255, 255, 255, 0.482);
}

.cont-caracteristicas-main-detalles > div > p {
  height: auto;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: white;
  margin: 0px;
}

.cont-caracteristicas-main-detalles > div > label {
  height: auto;
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: white;
  margin: 0px;
}

.cont-div3-main-desc-propiedad {
  padding-left: 35px;
  padding-right: 35px;
  width: 100%;
  height: auto;
}

.cont-div3-main-desc-propiedad > h3 {
  height: auto;
  width: 100%;
  text-align: left;
  font-weight: 300;
  font-size: 24px;
  color: rgb(0, 0, 0);
  margin-bottom: 20px;
}

.cont-div3-main-desc-propiedad > p {
  height: auto;
  width: 100%;
  text-align: left;
  font-weight: 200;
  font-size: 16px;
  color: rgb(0, 0, 0);
}

.cont-div3-main-desc-propiedad > h4 {
  height: auto;
  width: 100%;
  text-align: left;
  font-weight: 300;
  font-size: 20px;
  color: rgb(0, 0, 0);
}

.cont-div4-main-desc-propiedad {
  padding-left: 35px;
  padding-right: 35px;
  width: 100%;
  height: auto;
}

.cont-div4-main-desc-propiedad > h3 {
  height: auto;
  width: 100%;
  text-align: left;
  font-weight: 300;
  font-size: 24px;
  color: rgb(0, 0, 0);
}

.cont-div5-main-desc-propiedad {
  padding-left: 35px;
  padding-right: 35px;
  width: 100%;
  height: auto;
}

.cont-div5-main-desc-propiedad > h3 {
  height: auto;
  width: 100%;
  text-align: left;
  font-weight: 300;
  font-size: 24px;
  color: rgb(0, 0, 0);
}

.cont-footer-propiedad-item-desc {
  width: 100%;
  min-height: 160px;
  max-height: 350px;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  /* background-color: red; */
  margin-top: 20px;
  margin-bottom: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cont-footer-propiedad-item-desc-items {
  width: 33.3%;
  height: 150px;
  flex-direction: row;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(24, 168, 101); */
  cursor: pointer;
  text-decoration: none;
}

.cont-footer-propiedad-item-desc-items > img {
  width: 150px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.cont-footer-propiedad-item-desc-items > h5 {
  height: auto;
  width: 100%;
  text-align: left;
  font-weight: 300;
  font-size: 20px;
  margin-left: 20px;
  color: rgb(0, 0, 0);
  cursor: pointer;
}

@media (max-width: 980px) {
  .cont-back-home-descpropiedad {
    margin-top: 130px;
    margin-bottom: 20px;
  }

  .cont-div1-div2-propiedad {
    height: auto;
    flex-direction: column;
  }

  .cont-div1-main-desc-propiedad {
    width: 100%;
    /* height: 100%; */
    /* background-color: red; */
  }

  .cont-div1-div2-propiedad {
    padding-left: 10px;
    padding-right: 10px;
  }

  .cont-div2-main-desc-propiedad > .fecha-crecion {
    margin-top: 30px;
  }

  .cont-div2-main-desc-propiedad {
    width: 100%;
  }

  .cont-precio-header-desc-propiedades > label {
    font-size: 17px;
  }

  .tipo-venta-desc-propiedades > label {
    font-size: 15px;
  }

  .cont-div3-main-desc-propiedad {
    margin-top: 30px;
  }

  .cont-div3-main-desc-propiedad > p {
    font-weight: normal;
    font-size: 14px;
  }
}

@media (max-width: 850px) {
  .cont-main-desc-propiedad-div-2 {
    width: 100%;
  }

  .cont-footer-propiedad-item-desc-items {
    width: 50%;
  }
}

@media (max-width: 690px) {
}

@media (max-width: 600px) {
  .cont-caracteristicas-main-detalles {
    height: 160px;
  }

  .cont-caracteristicas-main-detalles > div {
    height: 80px;
  }

  .cont-caracteristicas-main-detalles > div > p {
    font-size: 14px;
  }

  .cont-caracteristicas-main-detalles > div > label {
    font-size: 12px;
  }
}

@media (max-width: 560px) {
  .cont-footer-propiedad-item-desc-items {
    width: 100%;
  }
}

@media (max-width: 370px) {
}
