.mainContainerContacto {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 20%;
  padding-right: 20%;
}

.mainContainerContacto > h3 {
  width: 100%;
  font-size: 30px;
  text-align: center;
  font-weight: 400;
  color: #c9a857;
  margin-bottom: -5px;
}

.borderDecoradorContacto {
  height: 2px;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 90px !important;
  background-color: #c9a857;
}

.mainContainerContacto > p {
  width: 100%;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.mainContainerContacto > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 760px) {
  .mainContainerContacto {
    padding-left: 0%;
    padding-right: 0%;
    padding-top: 20px;
  }

  .mainContainerContacto > h3 {
    font-size: 23px;
    margin-bottom: 0px;
  }

  .mainContainerContacto > p {
    font-size: 20px;
    margin-bottom: -40px;
  }
}
