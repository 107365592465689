.cont-caratula-folleto {
  width: 100%;
  height: 190px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: center;
}

.filelabel {
  border-radius: 5px;
  overflow: hidden;
  display: block;
  /* padding: 5px; */
  transition: border 300ms ease;
  cursor: pointer;
  text-align: center;
  margin: 0;
}

.filelabel > img {
  width: 80%;
}

.filelabel i {
  display: block;
  font-size: 30px;
  padding-bottom: 5px;
}

.filelabel > input {
  overflow: hidden;
}

.filelabel i,
.filelabel .title {
  color: grey;
  transition: 200ms color;
}

.title {
  font-size: 13px;
  text-transform: capitalize;
  color: grey;
  transition: 200ms color;
}

.filelabel:hover i,
.filelabel:hover .title {
  color: rgb(99, 92, 92);
}

.FileUpload1 {
  display: none;
}

.docs-archivos-prop {
  margin-top: 20px;
  width: 100%;
  padding-top: 30px;
  max-height: 300px;
  display: flex;
  overflow-x: auto;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap;
}

.docs-archivos-prop > div {
  width: 45%;
  height: 140px;
  overflow: hidden;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.docs-archivos-prop > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.inputFormLabel {
  margin-top: 10px;
  font-size: 16.5px;
  width: 100%;
  font-weight: 500;
}

.inputForm {
  margin-top: 5px;
  height: 40px;
  width: 90%;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  border-radius: 4px;
  /* background-color: #f5f1f1 !important; */
  border: 1.2px solid #c5c5c5 !important;
  outline: none;
  border: none;
}

.inputForm::placeholder {
  color: rgb(182, 174, 174);
  font-size: 16px;
  font-weight: 400;
}

.cont-double-input-form {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.cont-double-input-form > div {
  width: 50%;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* background-color: rgb(27, 89, 127); */
}

.cont-double-input-form > div > input {
  width: 90%;
}

.inputForm-textarea {
  width: 100%;
  margin-top: 5px;
  min-height: 100px;
  font-size: 16px;
  font-weight: 400;
  padding-left: 15px;
  border-radius: 4px;
  /* background-color: #f5f1f1 !important; */
  border: 1.2px solid #c5c5c5 !important;
}

@media (max-width: 800px) {
  .docs-archivos-prop > div {
    width: 43%;
  }
}
