.cont-prin-header {
  width: 100%;
  height: 70px;
  min-height: 70px;
  /* background-color: red; */
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.044);
  z-index: 15;
}

.cont-prin-1-header {
  width: 40%;
  height: 100%;
  /* background-color: rgb(221, 19, 19); */
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  padding-left: 35px;
  justify-content: flex-start;
  align-items: center;
}

.cont-prin-1-header > div {
  width: 35px;
  height: 35px;
  /* background-color: rgb(255, 247, 0); */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-radius: 50%;
}

.cont-prin-1-header > div:hover {
  cursor: pointer;
  background-color: rgba(0, 13, 255, 0.061);
  transition: 0.3s;
}

.cont-prin-1-header > div > img {
  width: 70%;
  height: 70%;
  object-fit: cover;
  user-select: none;
}

.cont-prin-2-header {
  width: 60%;
  height: 100%;
  /* background-color: rgb(0, 13, 251); */
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
}

.cont-header-campana {
  margin-right: 10px;
  width: 35px;
  height: 35px;
  /* background-color: rgb(255, 247, 0); */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-radius: 50%;
}
.cont-header-contador {
  position: relative;
  left: 30px;
  bottom: 10px;
  width: 8px;
  height: 8px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #0a2759;
}

.cont-header-campana:hover {
  cursor: pointer;
  background-color: rgba(0, 13, 255, 0.061);
  transition: 0.3s;
}

.cont-header-campana > img {
  width: 60%;
  height: 60%;
  object-fit: cover;
  overflow: hidden;
  user-select: none;
}

.cont-header-logo {
  width: auto;
  min-width: 210px;
  height: 45px;
  /* background-color: rgb(255, 247, 0); */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  border-radius: 20px;
  user-select: none;
}

.cont-header-logo:hover {
  cursor: pointer;
  background-color: rgba(0, 13, 255, 0.061);
  transition: 0.3s;
  cursor: pointer;
}

.cont-header-logo-1 {
  width: 70%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  padding-right: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: red; */
}

.cont-header-logo-1:hover {
  cursor: pointer;
}

.cont-header-logo-1-label-1 {
  font-weight: bold;
  color: black;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cont-header-logo-1-label-1:hover {
  cursor: pointer;
}

.cont-header-logo-1-label-2 {
  font-weight: 400;
  color: grey;
  font-size: 14px;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cont-header-logo-1-label-2:hover {
  cursor: pointer;
}

.cont-header-logo-2 {
  width: 30%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(100, 4, 255); */
}

.cont-header-logo-2:hover {
  cursor: pointer;
}

.cont-header-logo-2 > div {
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  /* background-color: red; */
}

.cont-header-logo-2 > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
}

.cont-link-profile {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: rgb(32, 31, 31);
  /* background-color: red; */
}

.cont-link-profile:hover {
  color: rgb(32, 31, 31);
}

@media (max-width: 450px) {
  .cont-prin-1-header {
    width: 20%;
  }

  .cont-prin-2-header {
    width: 80%;
    overflow-x: auto;
    overflow-y: auto;
  }

  .cont-prin-2-header {
    padding-right: 20px;
  }

  .cont-prin-1-header {
    padding-left: 25px;
  }
}
