.mainContQuienesSomos {
  width: 100%;
  min-height: 75%;
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 20%;
  padding-right: 20%;
}

.mainContQuienesSomos > h1 {
  width: 100%;
  font-size: 45px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #0c508c;
}

.mainContQuienesSomos > p {
  width: 100%;
  font-size: 20px;
  text-align: justify;
  font-weight: 400;
  color: rgb(0, 0, 0);
}

.btn-conocenos-quienes-somos {
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 50px;
  transition-duration: 0.3s;
}

.btn-conocenos-quienes-somos:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.751);
  transition-duration: 0.3s;
}

.btn-conocenos-quienes-somos > label {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  text-align: center;
  user-select: none;
  color: white;
  cursor: pointer;
}

@media (max-width: 760px) {
  .mainContQuienesSomos {
    min-height: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 5%;
    padding-right: 5%;
  }

  .mainContQuienesSomos > h1 {
    font-size: 35px;
  }

  .mainContQuienesSomos > p {
    font-size: 15px;
    margin-top: -20px;
  }
}
