.content-prin-background-sidebar {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.436);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  z-index: 50;
}

.content-prin-sidebar-1 {
  position: relative;
  /* left: 0%; */
  width: 25%;
  height: 100vh;
  background-color: #072654;
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  box-shadow: 4px 2px 8px 0 rgba(0, 0, 0, 0.2),
    1px 6px 5px 0 rgba(0, 0, 0, 0.044);
}

.content-prin-sidebar-0 {
  width: 25%;
  height: 100vh;
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.content-prin-sidebar-header {
  width: 100%;
  height: 70px;
  background-color: #0a2759;
  transition: 0.5s;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

.cont-img-sidebar-logo {
  width: 70%;
  height: 100%;
  /* background-color: #95ff00; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-img-sidebar-logo > img {
  width: 80%;
  height: 80%;
  object-fit: contain;
  user-select: none;
}

.cont-img-sidebar-menu {
  width: 30%;
  height: 100%;
  /* background-color: #f700f2; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cont-img-sidebar-menu div {
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  transition: 0.5s;
  justify-content: center;
  align-items: center;
}

.cont-img-sidebar-menu div:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.061);
  transition: 0.3s;
}

.cont-img-sidebar-menu div > img {
  width: 50%;
  height: 50%;
  object-fit: contain;
  user-select: none;
}

.content-segun-sidebar-all {
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  /* background-color: #83aaec; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

.cont-perfil-sidebar {
  width: 100%;
  min-height: 200px;
  height: 200px;
  background-color: #2f094f;
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  text-decoration: none !important;
}

.cont-perfil-sidebar > div {
  width: 90px;
  height: 90px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #0a2759;
  /* background-color: red; */
}

.cont-perfil-sidebar > div > img {
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
}

.label-perfil-sidebar-1 {
  margin-top: 15px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.label-perfil-sidebar-2 {
  font-weight: 600;
  color: rgb(205, 205, 205);
  font-size: 14px;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.items-menu-sidebar {
  width: 95%;
  min-height: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  /* background-color: red; */
  /* background-color: rgba(255, 255, 255, 0.061); */
  transition: 0.3s;
  text-decoration: none;
}

.items-menu-sidebar:hover {
  background-color: rgba(0, 0, 0, 0.645);
  transition: 0.3s;
}

.active-items-menu-sidebar {
  background-color: rgba(0, 0, 0, 0.645);
  transition: 0.3s;
}

.active-items-menu-sidebar > .items-menu-sidebar-1 > i {
  color: rgb(255, 255, 255);
}

.active-items-menu-sidebar > .items-menu-sidebar-2 > label {
  color: rgb(255, 255, 255);
}

.items-menu-sidebar-1 {
  width: 20%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.items-menu-sidebar-1 > i {
  color: rgba(174, 174, 174, 0.838);
  font-size: 16px;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.items-menu-sidebar-2 {
  width: 80%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  /* background-color: rgb(39, 85, 255); */
}

.items-menu-sidebar-2 > label {
  font-weight: 600;
  color: rgba(174, 174, 174, 0.838);
  font-size: 14px;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.div-siderbar-label {
  margin-top: 30px;
  margin-bottom: 10px;
  width: 88%;
  height: 30px;
  min-height: 30px;
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
}

.div-siderbar-label > strong {
  font-weight: 600;
  color: rgba(213, 198, 0, 0.843);
  font-size: 14px;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.items-menu-sidebar-3 {
  width: 20%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  /* background-color: red; */
}

.items-menu-sidebar-3 > i {
  color: rgba(174, 174, 174, 0.838);
  font-size: 20px;
  white-space: nowrap;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*  */
.content-prin-sidebar-2 {
  width: 75%;
  height: 100vh;
  /* background-color: #687200; */
  transition: 0.5s;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}

@media (max-width: 1100px) {
  .content-prin-sidebar-1 {
    width: 35%;
  }

  .content-prin-sidebar-2 {
    width: 65%;
  }
}

@media (max-width: 920px) {
  .content-prin-sidebar-1 {
    width: 40%;
  }

  .content-prin-sidebar-2 {
    width: 60%;
  }
}

@media (max-width: 800px) {
  .content-prin-sidebar-1 {
    width: 50%;
  }

  .content-prin-sidebar-2 {
    width: 50%;
  }
}

@media (max-width: 650px) {
  .content-prin-sidebar-1 {
    width: 65%;
  }

  .content-prin-sidebar-2 {
    width: 35%;
  }
}

@media (max-width: 480px) {
  .content-prin-sidebar-1 {
    width: 72%;
  }

  .content-prin-sidebar-2 {
    width: 28%;
  }
}
