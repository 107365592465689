@import url("https://fonts.googleapis.com/css2?family=Asap:ital,wght@1,500&display=swap");

.cont-div1-main-detalles-blog-new {
  width: 100vw;
  height: 140vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: #fff;

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  padding-top: 100px;
}

.cont-div1-main-detalles-blog-new-2 {
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
}

/*  */
/*  */

.cont-principal-blogdesc-final {
  width: 100%;
  height: 100vh;
  overflow-x: scroll;
  margin-top: 70px;
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  /* background-color: #d7d7d7; */
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.cont-1-desc-principal-item {
  width: 70%;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  /* border-top: 3.5px solid #022951;
  border-bottom: 3.5px solid #022951; */
  border-radius: 3px;
  /* background-color: white; */
  padding: 25px;
}

.cont-1-desc-principal-item:hover {
  /* border-top: 3.5px solid #c9a857;
  border-bottom: 3.5px solid #c9a857; */
  /* cursor: pointer; */
}

.cont-img-blog-decs {
  /* background-color: white; */
  width: 100%;
  height: 350px;
  overflow: hidden;
}

.cont-img-blog-decs > img {
  width: 100%;
  height: 100%;
  border-radius: 3px;
  object-fit: cover;
}

.cont-title-detalles-blog-decs {
  width: 100%;
  max-height: 170px;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  /* background-color: rgb(0, 0, 0); */
}

.cont-desc-title-decs {
  /* background-color: red; */
  /* margin-top: 25px; */
  width: 85%;
  max-height: 135px;
  text-align: justify;
  overflow: hidden;
}

.cont-desc-title-decs > h2 {
  font-size: 25px;
  letter-spacing: 1px;
  color: rgb(21, 80, 49);
  font-weight: 500;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

.cont-desc-fecha-blog-decs {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  max-height: 35px;
  overflow: hidden;
}

.cont-desc-fecha-blog-decs > label {
  width: 100%;
  height: 100%;
  font-size: 15px;
  color: grey;
  text-overflow: ellipsis;
}

.cont-description-blog-decs {
  margin-top: 5px;
  width: 100%;
  height: auto;
  overflow-x: scroll;
}

.cont-description-blog-decs > p {
  width: 100%;

  color: black;
  font-size: 20px;
  text-overflow: ellipsis;
}

.cont-btn-blog-decs {
  /* background-color: blue; */
  margin-top: 15px;
  width: 100%;
  height: 40px;
  overflow: hidden;
}

.cont-2-desc-principal-item {
  /* background-color: red; */
  width: 25%;
  padding-left: 30px;
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.cont-2-desc-principal-item h2 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Asap", sans-serif !important;
  margin-bottom: 15px;
}

.cont-2-desc-item-desc {
  /* border-top: 3.5px solid #022951;
  border-bottom: 3.5px solid #022951; */
  padding-top: 13px;
  padding-left: 13px;
  padding-right: 13px;
  background-color: white;
  width: 100%;
  height: 170px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  text-decoration: none !important;
  margin-bottom: 10px;
}

.cont-2-desc-item-desc:hover {
  /* border-top: 3.5px solid #c9a857;
  border-bottom: 3.5px solid #c9a857; */
  cursor: pointer;
}

.cont-2-desc-item-desc > div {
  width: 90%;
  height: 110px;
  overflow: hidden;
}

.cont-2-desc-item-desc > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

.cont-2-desc-item-desc > h4 {
  height: 20px;
  overflow: hidden;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none !important;
  text-transform: uppercase;
  text-overflow: ellipsis;
}

/* Movil */
.contenedor-principal-movil-blog-decs {
  display: none;
}

@media (max-width: 950px) {
  .cont-principal-blogdesc-final {
    display: none;
  }

  /* Movil */
  .contenedor-principal-movil-blog-decs {
    display: flex;
    margin-top: 70px;
    width: 100vw;
    height: auto;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    /* background-color: red; */
  }

  .cont-prin-movil-blog-titulo {
    padding-top: 25px;
    overflow: hidden;
    width: 95vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column wrap;
    /* background-color: rgb(172, 201, 228); */
  }

  .cont-prin-movil-blog-titulo > h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .cont-prin-movil-blog-titulo > a {
    width: 85vw;
    height: 45px;
    color: #c9a857;
    display: flex;
    font-family: "Asap", sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 20px;
    border-radius: 3px;
    margin-bottom: 40px;
    overflow: hidden;
    background-color: #022951;
  }

  .cont-prin-movil-blog-titulo > a:hover {
    background-color: #083664;
    cursor: pointer;
  }

  .cont-prin-movil-blog-caratula {
    width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .cont-prin-movil-blog-caratula > div {
    background-color: #d7d7d7;
    width: 90%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .cont-prin-movil-blog-caratula > div > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    overflow: hidden;
  }

  .cont-prin-movil-blog-caratula > label {
    margin-top: 15px;
    width: 100vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    font-size: 18px;
    margin-left: 30px;
    color: grey;
  }

  .cont-prin-movil-blog-contenido {
    /* background-color: red; */
    margin-top: 0px;
    width: 95%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-x: scroll;
  }

  .cont-prin-movil-blog-cont-mas-items {
    border-top: 2px solid rgba(189, 189, 189, 0.464);
    margin-top: 50px;
    width: 100vw;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-left: 16px;
    align-items: flex-start;
  }

  .cont-prin-movil-blog-cont-mas-items > p {
    padding-top: 10px;
    font-size: 19px;
    margin-bottom: 25px;
    font-family: "Asap", sans-serif !important;
  }

  .cont-prin-movil-blog-cont-mas-items-todos {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    /* padding-left: 16px; */
    align-items: center;
  }

  .cont-prin-movil-blog-cont-mas-items-todos > a {
    width: 95vw;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    /* padding-left: 16px; */
    align-items: center;
    margin-bottom: 22px;
    color: black;
    text-decoration: none;
  }

  .cont-prin-todos-img {
    /* background: red; */
    width: 30%;
    display: flex;
    justify-content: center;
    /* padding-left: 16px; */
    align-items: center;
    height: 100px;
    overflow: hidden;
  }

  .cont-prin-todos-img > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .cont-prin-todos-img > div > img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    object-fit: cover;
  }

  .cont-prin-todos-p {
    /* background: blue; */
    width: 70%;
    height: 100px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    padding-left: 22px;
    align-items: flex-start;
    overflow: hidden;
  }

  .cont-prin-todos-p > p {
    text-align: justify;
  }
}

@media (max-width: 500px) {
  .cont-prin-movil-blog-titulo > h2 {
    font-size: 23px;
  }

  .cont-prin-movil-blog-titulo > a {
    height: 43px;
    font-size: 18px;
    text-decoration: none;
  }

  .cont-prin-movil-blog-caratula > label {
    font-size: 15px;
  }

  .cont-prin-movil-blog-cont-mas-items-todos > a {
    flex-flow: column nowrap;
    color: black;
    text-decoration: none;
  }

  .cont-prin-todos-img {
    width: 90%;
    height: 150px;
    margin-bottom: 15px;
  }

  .cont-prin-todos-p {
    /* background: blue; */
    width: 90%;
    height: 120px;
    padding-left: 0px;
    margin-bottom: 10px;
  }
}
