.mainContainerWelcolme {
  width: 100%;
  height: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)),
    url("../../../../img/apartamento33.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  overflow: hidden;
  border-radius: 10px;
  background-color: #022a51;
}

.cont-welcolme-izq {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-welcolme-izq > img {
  height: 100%;
  object-fit: cover;
  transform: scaleX(-1);
  border-radius: 10px;
}

.cont-main-wel-center {
  width: 100%;
  padding-left: 20%;
  padding-right: 20%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cont-div-logo-wel {
  /* width: 400px; */
  height: 150px;
  object-fit: contain;
  margin-bottom: 60px;
}

.cont-main-wel-center > h1 {
  width: 100%;
  font-size: 50px;
  text-align: center;
  font-weight: bold;
  color: #c9a857;
  margin-bottom: 25px;
}

.cont-main-wel-center > p {
  width: 100%;
  font-size: 20px;
  text-align: center;
  font-weight: 100;
  color: rgb(255, 255, 255);
  margin-bottom: 30px;
}

.cont-contactanos-welcolme {
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 50px;
  margin: 0px;
  transition-duration: 0.3s;
}

.cont-contactanos-welcolme:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.751);
  transition-duration: 0.3s;
}

.cont-contactanos-welcolme > h3 {
  margin: 0px;
  padding: 0px;
  font-size: 18px;
  text-align: center;
  user-select: none;
  color: white;
  cursor: pointer;
}

.cont-arrow-welcolme {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cont-arrow-welcolme > a > img {
  width: 50px;
  object-fit: contain;
  /* filter: #c9a857; */
  margin-top: 20px;
  filter: invert(0.4) sepia(1) hue-rotate(20deg) saturate(1000%);
}
.cont-welcolme-der {
  padding-left: 0px;
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 942px) {
  .cont-main-wel-center {
    padding-left: 5%;
    padding-right: 5%;
  }

  .cont-div-logo-wel {
    width: 70%;
  }

  .cont-main-wel-center > h1 {
    font-size: 30px;
  }

  .cont-main-wel-center > p {
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
